import Swiper from 'swiper/bundle';

export const initTestimonialsSlider = () => {
    new Swiper('[data-slider="testimonials"]', {
        slidesPerView: 1,
        spaceBetween: 11,
        centeredSlides: true,
        grabCursor: true,
        navigation: {
            nextEl: '[data-slider-testimonials-button-next]',
            prevEl: '[data-slider-testimonials-button-prev]',
        },
        pagination: {
            el: '[data-slider-testimonials-pagination]',
            clickable: true,
        },
        loop: true,
        loopedSlides: 4,
        breakpoints: {
            680: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 56,
                centeredSlides: false,
            },
        },
    });
};
