import anime from 'animejs/lib/anime.es.js';

export const initAccordion = () => {
    const toggles = document.querySelectorAll('[data-accordion-toggle]');
    if (!toggles.length) return;

    toggles.forEach((toggle) => {
        toggle.addEventListener('click', handleOnClick, false);
    });
};

const duration = 600;
const activeClass = 'is-open-accordion';
function handleOnClick() {
    const accordion = this.closest('[data-accordion]');
    accordion.classList.contains(activeClass) ? closeAccordion(accordion) : openAccordion(accordion);
}
function openAccordion(accordion) {
    const body = accordion.querySelector('[data-accordion-body]');
    const height = body.scrollHeight;
    accordion.classList.add(activeClass);

    anime({
        targets: body,
        height: [0, height],
        easing: 'linear',
        duration: duration,
        complete: function () {
            body.style.height = 'auto';
        },
    });
}

function closeAccordion(accordion) {
    const body = accordion.querySelector('[data-accordion-body]');
    const height = body.scrollHeight;
    body.style.height = `${height}px`;
    accordion.classList.remove(activeClass);

    anime({
        targets: body,
        height: 0,
        easing: 'linear',
        duration: duration,
    });
}
