import JustValidate from 'just-validate/dist/js/just-validate.min';
import { isReCaptchaValid, removeRecaptchaError, setRecaptchaError } from './form-components/ReCaptchaActions';

export const initContactForm = () => {
    const formSelector = '[data-contact-form]';
    const contactForm = document.querySelector(formSelector);
    if (!contactForm) return;

    const formAction = new window.JustValidate(formSelector, {
        rules: {
            email: {
                required: true,
                email: true,
            },
            subject: {
                required: true,
            },
            message: {
                required: true,
            },
        },

        submitHandler: function (form, values, ajax) {
            if (isReCaptchaValid()) {
                removeRecaptchaError(formSelector);
            } else {
                setRecaptchaError(formSelector);
                return;
            }

            ajax({
                url: form.getAttribute('action'),
                method: 'POST',
                data: values,
                async: true,
                callback: function (response) {
                    resetForm(form);
                    showMessageSuccessesSend();
                },
                error: function (response) {
                    console.log(response);
                },
            });
        },

        invalidFormCallback: function (errors) {
            if (errors && !isReCaptchaValid()) {
                setRecaptchaError(formSelector);
            }
        },
    });

    function resetForm(form) {
        grecaptcha.reset();
        form.reset();
        removeRecaptchaError('#contact-form');
    }

    function showMessageSuccessesSend() {
        const message = document.querySelector('[data-successfully-sent]');
        message.classList.add('is-show');

        setTimeout(() => {
            message.classList.remove('is-show');
        }, 5000);
    }
};
