import Swiper from 'swiper/bundle';

export const initSocialMediaSlider = () => {
    const descriptionsSlider = new Swiper('[data-slider="social-media-descriptions"]', {
        slidesPerView: 1,
        spaceBetween: 11,
        grabCursor: true,
        navigation: {
            nextEl: '[data-slider-social-media-button-next]',
            prevEl: '[data-slider-social-media-button-prev]',
        },
        loop: true,
        loopedSlides: 10,
    });
    const iconsSlider = new Swiper('[data-slider="social-media-icons"]', {
        centeredSlides: true,
        slidesPerView: 'auto',
        grabCursor: true,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        loop: true,
        loopedSlides: 10,
    });

    descriptionsSlider.controller.control = iconsSlider;
    iconsSlider.controller.control = descriptionsSlider;
};
