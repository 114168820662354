import { isValidMail } from '../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';

export function initCreateAccountEmail() {
    const forms = document.querySelectorAll('[data-create-account-form]');

    [...forms].forEach((form) => {
        initForm(form);
    });
}

function initForm(form) {
    const userEmail = form.querySelector('[data-create-account-email]');
    const fieldsSelector = {
        userEmail: '[data-create-account-email]',
    };
    const classShowErrorMessage = 'is-error';
    let isValidForm = true;

    // LISTENERS
    form.addEventListener('submit', handleOnSubmit, false);

    userEmail.addEventListener('focus', handleOnFocusFieldUserEmail, false);

    // HANDLERS
    function handleOnSubmit(event) {
        event.preventDefault();
        validateUserEmail();

        if (!isValidForm) {
            isValidForm = true;
        } else {
            window.location.href = `${form.dataset.createAccountForm}?email=${encodeURIComponent(
                userEmail.value.trim(),
            )}`;
        }
    }

    function handleOnFocusFieldUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);
    }

    // METHODS & FUNCTIONS
    function validateUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);

        if (!userEmail.value.trim()) {
            showErrorMessage(fieldsSelector.userEmail, 'required');
            isValidForm = false;
        } else if (!isValidMail(userEmail.value.trim())) {
            showErrorMessage(fieldsSelector.userEmail, 'invalid');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.userEmail);
        }
    }

    function hideErrorMessage(selector) {
        const field = form.querySelector(selector);
        const errors = field.closest('[data-input-wrapper]').querySelectorAll('[data-field-error]');

        field.classList.remove(classShowErrorMessage);
        [...errors].forEach((error) => {
            error.classList.remove(classShowErrorMessage);
        });
    }

    function showErrorMessage(selector, type) {
        const field = form.querySelector(selector);
        const error = field.closest('[data-input-wrapper]').querySelector(`[data-field-error="${type}"]`);

        field.classList.add(classShowErrorMessage);
        error.classList.add(classShowErrorMessage);
    }
}
