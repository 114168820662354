export const isReCaptchaValid = () => {
    return Boolean(grecaptcha.getResponse());
};

export const setRecaptchaError = (formSelector) => {
    const form = document.querySelector(formSelector);
    const errorMessage = form.querySelector('[data-recaptcha-error]');
    if (errorMessage) {
        errorMessage.classList.add('not-valid');

        setTimeout(() => {
            errorMessage.classList.remove('not-valid');
        }, 2000);
    }
};

export const removeRecaptchaError = (formSelector) => {
    const form = document.querySelector(formSelector);
    if (!form) return;
    const errorMessage = form.querySelector('[data-recaptcha-error]');
    if (errorMessage) {
        errorMessage.classList.remove('not-valid');
    }
};
