import { Modal } from '../components/Modal';
import { initForms } from '../components/initForms';
import { initAccordions } from '../components/initAccordions';
import { initScrollLocker } from '../assets/scrollLocker';
import { initScrollToAnchor } from '../components/initScrollToAnchor';
import { initSocialMediaSlider } from '../components/sliders/initSocialMediaSlider';
import { initTestimonialsSlider } from '../components/sliders/initTestimonialsSlider';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

function handleOnLoadPage() {
    initScrollLocker();
    const modal = new Modal();
    initForms();
    initSocialMediaSlider();
    initTestimonialsSlider();
    initAccordions();
    initScrollToAnchor();
}
